'use client';

import { NextIntlClientProvider, RichTranslationValues } from 'next-intl';

export const defaultTranslationValues: RichTranslationValues = {
  br: () => <br />,
  important: (chunks) => {
    return <strong>{chunks}</strong>;
  },
};

export const IntlProvider = (
  props: Parameters<typeof NextIntlClientProvider>[0],
) => (
  <NextIntlClientProvider
    {...props}
    defaultTranslationValues={defaultTranslationValues}
  />
);
